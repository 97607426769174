<template>

    <div class="window">
        <div class="window__content window__content_wrong">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" width="48" height="48"><rect width="4" height="40" x="36.728" y="8.444" fill="#fff" rx="1" transform="rotate(45 36.728 8.444)"/><rect width="40" height="4" x="11.272" y="8.444" fill="#fff" rx="1" transform="rotate(45 11.272 8.444)"/></svg>
            <span class="window__alert-text">Switch your network</span>
            <button class="window__button-close" v-on:click="switchNetwork">Switch</button>
        </div>
    </div>


</template>

<script>
import store from '../store/index.js'

export default {
    
    name:"ModalNetwork",
    store,
    methods:
    {
        async switchNetwork()
        {
            try
            {
                // const provider = this.$store.state.provider
                if(window.ethereum)
                {
                    const id = process.env.VUE_APP_RIGHT_CHAIN_ID
                    let hexString = Number(id).toString(16)
                    const res = await window.ethereum.request({ 
                        method: 'wallet_switchEthereumChain',
                        params: [
                            {chainId: '0x' + hexString}
                            ]
                    });
                    this.$store.commit('SET_CURRENT_CHAIN_ID', id)

                    await this.$store.dispatch('fetchSaleData').then(async()=>{
                        await this.$store.dispatch('fetchTokenData')
                    })

                }
            }
            catch(err)
            {
                console.log(err)
            }
        }
    }
}
</script>

<style scoped>
        .window {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top:0;
            display: flex;
            align-items: flex-end;
            background: rgba(1, 37, 53, 0.6);

            z-index: 999;
            
        }
        
        .window__content {
            position: relative;
            left: 0;
            right: 0;
            bottom: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            max-width: 400px;
            height: 286px;
            margin: 0 auto;
            background: #fff;
            border-radius: 9px;
            margin-bottom: 15%;
            
            transition:all 3s ease-in-out;
        }
        
        .window__content_wrong {
            background: #D94848;
        }
        
        .window__alert-text {
            margin: 20px 0 44px;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
        }
        
        .window__button-close {
            width: 90%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E3E3E3;
            border-radius: 32px;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
            transition: all 0.25s ease;
        }
        
        .window__button-close:hover {
            background: #EBEBEB;
        }
        
        @media (min-width: 768px) {
            .window {
                align-items: center;
            }
        }

</style>