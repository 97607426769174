<template>
    <div>
        
        <div v-if="showWalletModal">
            <WalletPickModal/>
        </div>
        <div v-else-if="!!user && rightChainid != currentChainid">
            <ModalNetwork/>
        </div>
        

        <div v-if="!!user">
            <Header :home="true"/>
            <div v-if="showWallet">
                <Wallet/> 
            </div>
        </div>
        <div class="sale-window" >
            
            <div v-if="(!!user && Object.keys(currentRound).length != 0)|| loading">
                <Sale/>
            </div>
            <div v-else-if="(!!user && Object.keys(currentRound).length == 0 && salesData.length != 0 )|| loading">
                <h2 class="ps__title">Private Sale</h2>
                
                <p class="ps__desc">
                    The cassowary has often been labeled “the world’s most dangerous bird”
                </p>

                <div v-for="item in salesData" :key ="item.index" >
                    <div class="ps__info">
                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Start date:</span>

                            <span class="ps__info-item-value">{{item.dateStart}}</span>
                        </div>

                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Finish date:</span>

                            <span class="ps__info-item-value">{{item.dateLimit}}</span>
                        </div>
                        <div v-if="item.vestingNextTimestamp == 0 && !item.isCancelled && !item.finish && !item.timeout">
                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Total Vesting Time:</span>

                                <span class="ps__info-item-value">{{((item.vestingDays * item.vestingPeriod)/86400)}} days ({{((item.lockup)/86400)}} days lockup) </span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Tokens to sell:</span>

                                <span class="ps__info-item-value">{{item.tokensAmount}} CSR</span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Total committed:</span>

                                <span class="ps__info-item-value">${{item.totalReserve}}</span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Funds to raise:</span>

                                <span class="ps__info-item-value">${{item.tokenLimit}}</span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Price per CSR:</span>

                                <span class="ps__info-item-value">${{item.price}}</span>
                            </div>
                        </div>
                        <div v-else>
                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Status:</span>
                                <span class="ps__info-item-value" v-if="item.isCancelled">Canceled</span>
                                <span class="ps__info-item-value" v-else-if="item.vesting && parseFloat(item.myShare) > parseFloat(item.vestedShare)">Vesting</span>
                                <span class="ps__info-item-value" v-else>Finished</span>

                            </div>
                            <div class="ps__info-item">
                                <span class="ps__info-item-name">My share:</span>

                                <span class="ps__info-item-value">{{item.myShare}} CSR</span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Vested share:</span>

                                <span class="ps__info-item-value">{{item.vestedShare}} CSR</span>
                            </div>

                            <div class="ps__info-item">
                                <span class="ps__info-item-name">Balance to claim:</span>

                                <span class="ps__info-item-value">{{item.balanceToClaim}} CSR</span>
                            </div>
                        </div>
                        <div v-if="item.finish || item.timeout || parseFloat(item.depositedAmount) > 0 ">
                            <div v-if="loading && item == localItem">
                                <button class="ps__info-button info manage loading">Loading..</button>
                            </div>
                            <div v-else-if="item.isParticipated && (parseFloat(item.balanceToClaim) > 0 || parseFloat(item.depositedAmount) > 0)">
                                <button class="ps__info-button info manage" @click="open(item)">Manage</button>
                            </div>
                            <div v-else>
                                <button class="ps__info-button info disabled">Finished</button>
                            </div>
                        </div>
                        <div v-else-if="loading && item == localItem">
                            <button class="ps__info-button info loading">Loading..</button>
                        </div>
                        <div v-else>
                            <button class="ps__info-button info" @click="open(item)">Participate</button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div v-else-if="!isParticipated && !!user && !loading">
                <div class="sale-window-connect">
                    <div class="sale-window__logo"></div>

                    <h2 class="sale-window__title">CASSOWARY</h2>

                    <h3 class="sale-window__ps">Private Sale</h3>

                    <p class="sale-window__desc">
                        Your wallet is not in White list!
                    </p>

                    <button class="sale-window__button sale-window__button_red" v-on:click="disconnect">
                        Disconnect
                    </button>
                </div>

            </div>
            <div v-else-if="!loading">
                <div class="sale-window-connect">
                    <div class="sale-window__logo"></div>

                    <h2 class="sale-window__title">CASSOWARY</h2>

                    <h3 class="sale-window__ps">Private Sale</h3>

                    <p class="sale-window__desc">
                    The cassowary has often been labeled “the world’s most dangerous bird”
                    </p>

                    <button class="sale-window__button" v-on:click="connectWallet">
                        Invest
                    </button>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
// import Web3 from 'web3'
import store from '../store/index.js'
import { mapState } from 'vuex'
import WalletPickModal from '../components/WalletPickModal.vue'  
import ModalNetwork from '../components/ModalNetwork.vue'  
import Header from '../components/Header.vue' 
import Wallet from '../components/Wallet.vue' 
import Sale from '../components/Sale.vue' 

export default {
    name:"Start",
    store,
    components:
    {
        WalletPickModal,
        ModalNetwork,
        Header,
        Wallet,
        Sale,
        // ModalError,
        // ModalSuccess
    },
    created: async function()
    {

    },
    data()
    {
        return{
            loading:false,
            localItem: {},
            rightChainid: process.env.VUE_APP_RIGHT_CHAIN_ID
        }
    },
    computed:
    {
      ...mapState({
          
        web3: null,
        showWallet: 'showWallet',
        showWalletModal: 'showWalletModal',
        currentRound: 'currentRound',
        user: 'user',
        salesData: 'salesData',
        isParticipated: 'isParticipated',
        currentChainid: 'currentChainId',
        showModalError: 'showModalError',
        showModalSuccess: 'showModalSuccess',

      })
    },
    
    methods:
    {
        async connectWallet()
        {
            this.$store.commit('SET_WALLET_MODAL', true)
            // try
            // {  
            //     // let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            //     // this.$store.commit('SET_USER', accounts[0])
            //     // await this.$store.dispatch('fetchSaleData').then(async()=>{
            //     //     await this.$store.dispatch('fetchTokenData')
            //     // })
            // }
            // catch(err)
            // {
            //     console.log(err)
            // }
        },
        disconnect()
        {
            this.$store.commit('SET_USER', null)
        },
        async open(item)
        {
            try
            {
                this.localItem = item
                this.loading = true
                this.$store.commit('SET_CURRENT_ROUND', item)
                await this.$store.dispatch('fetchCurrentRound')
                this.loading = false
                this.$router.push({name: "round", params:{id: item.index}})
            }
            catch(err)
            {
                this.loading = false
                console.log(err)
            }
        },
        
    }
}
</script>

<style scoped>
        .sale-window {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

        }
        
        .sale-window__logo {
            width: 60px;
            height: 60px;
            margin: 0 auto 20px;
            background-image: url(~@/static/logo.png);
            background-color: #4C96D7;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
        
        .sale-window__title {
            margin-bottom: 10px;
            font-weight: 800;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .sale-window__ps {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .sale-window__desc {
            margin: 0 auto 30px;
            max-width: 304px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #012535;
        }
        
        .sale-window__button {
            width: 268px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            background-color: #218CEF;
            border-radius: 32px;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #FFFFFF;
            transition: all 0.25s ease;
        }
        
        .sale-window__button:hover {
            background-color: rgba(33, 140, 239, 0.5);
        }
        
        .sale-window__button_red {
            background-color: #D94848
        }
        
        .sale-window__button_red:hover {
            background-color: rgba(217, 72, 72, 0.5)
        }
                .ps__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }
        
        .ps__info-item {
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
            text-align: center;
        }
        
        .ps__info-item:not(:last-child) {
            margin-bottom: 10px;
        }
        
        .ps__info-item-name {
            width: 100%;
            margin-right: 12px;
            text-align: right;
        }
        
        .ps__info-item-value {
            width: 100%;
            text-align: left;
        }
        .ps__info-button {
            width: 200px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            background: #218CEF;
            border-radius: 32px;
            transition: all 0.25s ease;
        }
        
        .ps__info-button:hover {
            background: rgba(33, 140, 239, 0.5);
        }

        .manage
        {
            background: #E3E3E3;
            color:black;
        }
        .manage:hover
        {
            background: #f0eeee
        }
        .info
        {
            margin:12px;
        }
        .loading
        {
            transition: none;
            cursor: default;
        }
        .disabled
        {
            background: #dddddd;
            cursor:default;
        }
        .disabled:hover
        {
            background: #dddddd;
        }

                .ps__title {
            font-weight: 600;
            font-size: 32px;
            line-height: 30px;
            text-align: center;
            color: #012535;
            padding:26px;
            padding-bottom:24px;
        }
        
        .ps__desc {
            max-width: 303px;
            margin: 0 auto 40px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #012535;
        }
        .sale-window-connect
        {
            margin-top: 50%;
        }
        @media (min-width: 1000px)
        {
            .sale-window{
                padding-top:62px;
            }
            
        }
        
</style>