import Vue from 'vue'
import Vuex from 'vuex'
import Web3 from 'web3'
import saleAbi from '../abis/PrivateSale.json'
import ERC20 from '../abis/ERC20.json'
import Vesting from '../abis/Vesting.json'
import router from '../router'


Vue.use(Vuex)

const busdAddress = process.env.VUE_APP_BUSD_ADDRESS
const usdtAddress = process.env.VUE_APP_USDT_ADDRESS
const usdcAddress = process.env.VUE_APP_USDC_ADDRESS
const daiAddress = process.env.VUE_APP_DAI_ADDRESS

const saleContractAddress = process.env.VUE_APP_SELL_CONTRACT_ADDRESS
const csrAddress = process.env.VUE_APP_TOKEN_ADDRESS


const store = new Vuex.Store({
    state:
    {
        user: null,
        isModal: false,
        web3: null,
        errors: [],
        data:[
            
        ],
        provider: null,
        connected: false,
        whiteListAddresses:[],
        saleContract: null,
        csrContract: null,
        saleContractAddr: '',
        csrContractAddr:'',
        showWallet: false,
        currentRound: {},
        currentChainId: '',
        web3Modal: {
            web3Modal: null,
            web3: null,
            active: false,
            account: null,
            chainId: 0,
        },
        accountWasChanged: false,
        fetchingProblem: false,
        stableCoinsData:[
            {amount: '', nameSvg:"binance", symbol:"BUSD", name:"Binance USD", alt:"Binance", addr:busdAddress, decimals:"", approved:false},
            {amount: '', nameSvg:"tether", symbol:"USDT", name:"Tether", alt:"Tether", addr:usdtAddress, decimals:"", approved:false},
            {amount: '', nameSvg:"usdc", symbol:"USDC", name:"USDC", alt:"USDC", addr:usdcAddress, decimals:"", approved:false},
            {amount: '', nameSvg:"dai", symbol:"DAI", name:"DAI", alt:"DAI", addr:daiAddress, decimals:"", approved:false}
        ],
        pickedToken: {amount: '', nameSvg:"binance", symbol:"BUSD", name:"Binance USD", alt:"Binance", addr:busdAddress, decimals:"", approved:false},
        isApproved: false,
        accountInWhitelist: false,
        isLoaded: false,
        isFetched: false,
        vestingContract: null,
        showWalletModal: false,
        salesData: [],
        transactions: [],
        isParticipated: false,
        showModalSuccess: false,
        showModalError: false,
        walletWasDisconnected: false,
        isTransaction: false
    },
    mutations:
    {
        SET_USER(state, user)
        {
            state.user = user
        },
        SET_PROVIDER(state, provider)
        {
            state.provider = provider
        },
        SET_IS_TRANSACTION(state, flag)
        {
            state.isTransaction = flag
        },
        SET_CURRENT_CHAIN_ID(state, chainId)
        {
            state.currentChainId = chainId
        },
        SET_MODAL(state, flag)
        {
            state.isModal = flag
        },
        SET_IS_FETCHED(state, flag)
        {   
            state.isFetched = flag
        },
        SET_WALLET_MODAL(state, flag)
        {
          state.showWalletModal = flag  
        },
        SET_SHOW_SUCCESS(state, flag)
        {
            state.showModalSuccess = flag
        },
        SET_WALLET_WAS_DISCONNECTED(state, flag)
        {
            state.walletWasDisconnected = flag
        },
        SET_SHOW_ERROR(state, flag)
        {
            state.showModalError = flag
        },
        SET_WEB3(state, web3)
        {
            state.web3 = web3
        },
        SET_CONNECTED(state, connected)
        {
            state.connected = connected
        },
        SET_SHOW_WALLET(state, flag)
        {
            state.showWallet = flag
        },
        SET_IS_APPROVED(state, flag)
        {
            state.isApproved = flag
        },
        // SET_PROVIDER(state, provider)
        // {
        //     state.provider = provider
        // },
        SET_ERRORS(state, errors)
        {
            state.errors = errors
        },
        SET_CONTRACTS(state, contracts)
        {
            state.csrContract = contracts[0]
            state.saleContract = contracts[1]
        },
        SET_CONTRACT_ADDRESSES(state, addresses)
        {
            state.saleContractAddr = addresses[1]
            state.csrContractAddr = addresses[0]
        },
        SET_WHITELIST(state, list)
        {
            state.whiteListAddresses = list
        },
        SET_ROUND(state, obj)
        {
            state.currentRound = obj
        },
        SET_ACCOUNT_WAS_CHANGED(state, flag)
        {
            state.accountWasChanged = flag
        },
        SET_PICKED_TOKEN(state, token)
        {
            state.pickedToken = token
        },
        SET_APPROVE(state, flag)
        {
            state.pickedToken.approved = flag
        },
        SET_IS_LOADED(state, flag)
        {
            state.isLoaded = flag
        },
        SET_VESTING_CONTRACT(state, contract)
        {
            state.vestingContract = contract
        },
        SET_SALES_DATA(state, data)
        {
            state.salesData = data
        },
        SET_CURRENT_ROUND(state, round)
        {
            state.currentRound = round
        },
        SET_TRANSACTIONS(state, transactions)
        {
            state.transactions = transactions
        },
        SET_IS_PARTICIPATED(state, flag)
        {
            state.isParticipated = flag
        },
        PUSH_ERROR(state, error)
        {
            state.errors = state.errors.push(error)
        },
        setWeb3Modal(state, web3Modal) {
            state.web3Modal.web3Modal = web3Modal
        },
        setWeb3(state, web3) {
            state.web3Modal.web3 = web3
        },
        setActive(state, active) {
            state.web3Modal.active = active
        },
        setAccount(state, account) {
            state.web3Modal.account = account
        },
        setChainId(state, chainId) {
            state.web3Modal.web3Modal = chainId
        }
    },
    actions:
    {
        async fetchSaleData ({ commit, state }) {
            try
            {
                commit('SET_IS_LOADED', false)

                commit("SET_IS_FETCHED", false)
                
                commit("SET_IS_PARTICIPATED", false)
                if(state.accountWasChanged)
                {
                    commit("SET_SALES_DATA", [])
                }
                const provider = state.provider
                const web3 =  new Web3(provider)
                const saleContract = new web3.eth.Contract(saleAbi.abi, saleContractAddress)
                const csrContract = new web3.eth.Contract(ERC20.abi, csrAddress)
                const currentRoundId = await saleContract.methods.getCountRound().call()
                
                let data = []
                let options = {  
                    weekday: "long", year: "numeric", month: "short",  
                    day: "numeric", hour: "2-digit", minute: "2-digit"  
                };
                if(state.user && currentRoundId !== 0 && !state.isTransaction)
                {

                    for(let i = Number(currentRoundId)-1; i != -1; --i)
                    {
                        let isFinished = await saleContract.methods.isFinished(i).call()
                        let isParticipated = await saleContract.methods.isParticipatedInTheRound(i).call({from: state.user})
                        let isUserWhitelisted = await saleContract.methods.isInWhiteList(state.user).call() 
                        let roundStateInfo = await saleContract.methods.getRoundStateInfromation(i).call()
                        let open = roundStateInfo._open
                        let now = Date.now() / 1000
                        let timeout = parseInt(now) >= parseInt(roundStateInfo._endTimestamp)
                        if(isParticipated || (((i == Number(currentRoundId) - 1) && open)  && !isFinished) || (!open && isUserWhitelisted && !isFinished) )
                        {
                            
                            commit("SET_IS_PARTICIPATED", true)
                            // let roundTimeInfo = await saleContract.methods.getRoundTimeInfromation(i).call()
                            let dynamicInfo = await saleContract.methods.getRoundDynamicInfromation(i).call()
                            let lockedTokens = await saleContract.methods.getLockedTokens(i).call()
                            let vestingAddr = await saleContract.methods.getVestingAddress(i).call()
                            // let vestingInfo = await saleContract.methods.getRoundVestingInfromation(i).call()
                            let depositedAmount = await saleContract.methods.getInvestorDepositedTokens(i, state.user).call() 
                            let isCancelled = await saleContract.methods.isCancelled(i).call() 
                            const vestingContract = new web3.eth.Contract(Vesting.abi, vestingAddr)
                            let [vestedShare, balanceToClaim, myShare] = ['0', '0', '0']
                            let userToken = await saleContract.methods.getUserToken(i).call({from: state.user})
                            let token = ''
                            for(let i = 0; i < state.stableCoinsData.length; ++i)
                            {
                                if(web3.utils.toChecksumAddress(state.stableCoinsData[i].addr)  == web3.utils.toChecksumAddress(userToken))
                                {
                                    token = state.stableCoinsData[i].symbol
                                }
                            }
                            let vestingStartTimestamp = ''
                            let nextVestingDate = ''
                            let vesting = false
                            if(vestingAddr !== '0x0000000000000000000000000000000000000000')
                            {
                                var start = await vestingContract.methods.start().call()
    
                                if(start != 0)
                                {
                                    vestingStartTimestamp = Date.now()
                                    vestingStartTimestamp = parseInt(roundStateInfo._lockup)*1000 + vestingStartTimestamp - (Number(vestingStartTimestamp))%parseInt(roundStateInfo._duration) + (parseInt(start)*1000)%parseInt(roundStateInfo._duration) + parseInt(roundStateInfo._duration)*1000
                                    nextVestingDate = new Date(vestingStartTimestamp)
                                    nextVestingDate = nextVestingDate.toLocaleTimeString("en-us", options)
    
                                    myShare = await vestingContract.methods.getUserShare(state.user).call()
                                    vestedShare = await vestingContract.methods._vestedAmount(state.user).call()
                                    balanceToClaim = await vestingContract.methods.releasableAmount().call({from:state.user}) 
                                }
                            }
                
                            let startTimestamp = roundStateInfo._startTimestamp
                            let endTimestamp = roundStateInfo._endTimestamp
                            startTimestamp = Number(startTimestamp) * 1000
                            endTimestamp = Number(endTimestamp) * 1000
                            let startDate = new Date(startTimestamp)
                            let endDate = new Date(endTimestamp)
                            startDate = startDate.toLocaleTimeString("en-us", options)
                            endDate = endDate.toLocaleTimeString("en-us", options)
                            let now = Date.now() / 1000
                            vesting =  parseInt(roundStateInfo._endTimestamp) + parseInt(roundStateInfo._lockup) + (parseInt(roundStateInfo._duration) * parseInt(roundStateInfo._durationCount)) < parseInt(now) ? false : true
                            let obj={
                                tokensAmount: web3.utils.fromWei(roundStateInfo._sumTokens),
                                price: web3.utils.fromWei(roundStateInfo._tokenRate),
                                tokenLimit: web3.utils.fromWei(roundStateInfo._maxMoney),
                                maxAmount: web3.utils.fromWei(roundStateInfo._maximumSaleAmount),
                                minAmount: web3.utils.fromWei(roundStateInfo._minimumSaleAmount),            
                                percent: roundStateInfo._percentOnInvestorWallet,
                                type: roundStateInfo._type,
                                finish: isFinished,
                                burnable: roundStateInfo._burnable,
                                dateStart: startDate,
                                dateLimit: endDate,
                                totalReserve: web3.utils.fromWei(dynamicInfo[0]),
                                tokensDynamicAmount: web3.utils.fromWei(dynamicInfo[1]),
                                open: dynamicInfo[2],
                                lockedTokens: web3.utils.fromWei(lockedTokens),
                                index: i,
                                isParticipated: isParticipated,
                                lockup: parseInt(roundStateInfo._lockup),
                                vestingDays: parseInt(roundStateInfo._duration),
                                vestingPeriod: parseInt(roundStateInfo._durationCount),
                                vestingAddrr: vestingAddr,
                                depositedAmount: web3.utils.fromWei(depositedAmount),
                                isCancelled: isCancelled,
                                isWhitelisted: isUserWhitelisted,
                                myShare: web3.utils.fromWei(myShare),
                                vestedShare: web3.utils.fromWei(vestedShare),
                                balanceToClaim: web3.utils.fromWei(balanceToClaim),
                                vestingContract: vestingContract,
                                vestingStart: start,
                                token: token,
                                nextVestingDate:nextVestingDate,
                                vestingNextTimestamp: vestingStartTimestamp,
                                vesting: vesting,
                                startTimestamp: roundStateInfo._startTimestamp,
                                timeout: timeout
                            }
                            
                            data.push(obj)    
                           
                        }
                    }
                    if(data.length == 0)
                    {
                        commit("SET_IS_PARTICIPATED", false)
                    }
                    // else if(data.length === 1 && state.salesData.length > 1 && !state.accountWasChanged)
                    // {
                    //     let arr = state.salesData
                    //     arr[0] = data[0]
                    //     commit("SET_SALES_DATA", arr)
                    // }
                    // else if(data.length === 1 && !state.accountWasChanged )
                    // {
                    //     commit("SET_CURRENT_ROUND", data[0])
                    //     commit("SET_SALES_DATA", data)

                    // }
                    // else
                    // {
                        
                    // }
                    // commit("SET_VESTING_CONTRACT", vestingContract)
                }
                if(data.length === 1 )
                {
                    commit("SET_CURRENT_ROUND", data[0])
                }
                if(!state.isTransaction)
                {
                    commit("SET_SALES_DATA", data)
                }

                commit("SET_IS_FETCHED", true)

            }
            catch(err)
            {
                state.errors.push(err)
                console.log(err)
            }
        },
        
        async fetchTokenData ({ commit, state })
        {          
            try
            {
                const provider = state.provider
                const web3 =  new Web3(provider)
    
                if(state.user)
                {
                    for(let i = 0; i < state.stableCoinsData.length; ++i)
                    {
                        
                        const tokenContract = new web3.eth.Contract(ERC20.abi, state.stableCoinsData[i].addr)
                        let balance = await tokenContract.methods.balanceOf(state.user).call()
                        let allowance = await tokenContract.methods.allowance(state.user, saleContractAddress).call();
                        let tokenDecimals = await tokenContract.methods.decimals().call()
                        if(state.stableCoinsData[i].name == state.pickedToken.name && state.pickedToken.amount === '')
                        {
                            state.pickedToken.amount = balance/(10**tokenDecimals)
                            state.pickedToken.decimals = tokenDecimals
                            if(allowance.toString().length == 1)
                            {
                                state.pickedToken.approved = false
                            }
                            else
                            {
                                state.pickedToken.approved = true
                            }
                        }
                        state.stableCoinsData[i].amount = balance/(10**tokenDecimals)
                        state.stableCoinsData[i].decimals = tokenDecimals
                        
                        if(allowance.toString().length == 1)
                        {
                            state.stableCoinsData[i].approved = false
                        }
                        else
                        {
                            state.stableCoinsData[i].approved = true
                        }
                    }
                }
                commit("SET_IS_LOADED", true)
    
            }
            catch(err)
            {
                state.errors.push(err)
                console.log(err)
            }
        },
        pushTransaction({ commit, state }, transaction)
        {
            let data = []
            data = JSON.parse(window.localStorage.getItem(state.user)) || []
            data.push(transaction)
            window.localStorage.setItem(state.user, JSON.stringify(data))
            commit('SET_TRANSACTIONS', data.reverse())
        },
        async fetchCurrentRound({ dispatch, commit, state })
        {
            try
            {
                commit("SET_IS_FETCHED", false)
                commit("SET_IS_LOADED", false)
                const provider = state.provider
                const web3 =  new Web3(provider)
                const saleContract = new web3.eth.Contract(saleAbi.abi, saleContractAddress)
                const csrContract = new web3.eth.Contract(ERC20.abi, csrAddress)
                const currentRound = state.salesData[0]
                const index = currentRound.index
                let roundStateInfo = await saleContract.methods.getRoundStateInfromation(index).call()

                // let roundTimeInfo = await saleContract.methods.getRoundTimeInfromation(index).call()
                let dynamicInfo = await saleContract.methods.getRoundDynamicInfromation(index).call()
                let lockedTokens = await saleContract.methods.getLockedTokens(index).call()
                let isParticipated = await saleContract.methods.isParticipatedInTheRound(index).call({from: state.user})
                let vestingAddr = await saleContract.methods.getVestingAddress(index).call()
                // let vestingInfo = await saleContract.methods.getRoundVestingInfromation(index).call()
                let depositedAmount = await saleContract.methods.getInvestorDepositedTokens(index, state.user).call() 
                let isCancelled = await saleContract.methods.isCancelled(index).call() 
                let isUserWhitelisted = await saleContract.methods.isInWhiteList(state.user).call() 
                let isFinished = await saleContract.methods.isFinished(index).call()
                let userToken = await saleContract.methods.getUserToken(index).call({from: state.user})
                let token = ''
                let options = {  
                    weekday: "long", year: "numeric", month: "short",  
                    day: "numeric", hour: "2-digit", minute: "2-digit"  
                };
                for(let i = 0; i < state.stableCoinsData.length; ++i)
                {
                    if(web3.utils.toChecksumAddress(state.stableCoinsData[i].addr)  == web3.utils.toChecksumAddress(userToken))
                    {
                        token = state.stableCoinsData[i].symbol
                    }
                }
                const vestingContract = new web3.eth.Contract(Vesting.abi, vestingAddr)
    
                let [vestedShare, balanceToClaim, myShare] = ['0', '0', '0']
                let vestingStartTimestamp = ''
                let nextVestingDate = ''
                if(vestingAddr !== '0x0000000000000000000000000000000000000000')
                {
                    var start = await vestingContract.methods.start().call()
                    if(start != 0)
                    {
                        vestingStartTimestamp = Date.now()
                        vestingStartTimestamp = parseInt(roundStateInfo._lockup)*1000 + vestingStartTimestamp - (Number(vestingStartTimestamp))%parseInt(roundStateInfo._duration) + (parseInt(start)*1000)%parseInt(roundStateInfo._duration) + parseInt(roundStateInfo._duration)*1000
                        nextVestingDate = new Date(vestingStartTimestamp)
                        nextVestingDate = nextVestingDate.toLocaleTimeString("en-us", options)
    
                        myShare = await vestingContract.methods.getUserShare(state.user).call()
                        vestedShare = await vestingContract.methods._vestedAmount(state.user).call()
                        balanceToClaim = await vestingContract.methods.releasableAmount().call({from:state.user})    
                    }              
                               
                }

                let startTimestamp = roundStateInfo._startTimestamp
                let endTimestamp = roundStateInfo._endTimestamp
                startTimestamp = Number(startTimestamp) * 1000
                endTimestamp = Number(endTimestamp) * 1000
                let startDate = new Date(startTimestamp)
                let endDate = new Date(endTimestamp)
                startDate = startDate.toLocaleTimeString("en-us", options)
                endDate = endDate.toLocaleTimeString("en-us", options)
                let now = Date.now()/1000
                let vesting =  parseInt(roundStateInfo._endTimestamp) + parseInt(roundStateInfo._lockup) + (parseInt(roundStateInfo._duration) * parseInt(roundStateInfo._durationCount)) < parseInt(now) ? false : true
                let timeout = parseInt(now) >= parseInt(roundStateInfo._endTimestamp)
                let obj={
                    tokensAmount: web3.utils.fromWei(roundStateInfo._sumTokens),
                    price: web3.utils.fromWei(roundStateInfo._tokenRate),
                    tokenLimit: web3.utils.fromWei(roundStateInfo._maxMoney),
                    maxAmount: web3.utils.fromWei(roundStateInfo._maximumSaleAmount),
                    minAmount: web3.utils.fromWei(roundStateInfo._minimumSaleAmount),
                    percent: roundStateInfo._percentOnInvestorWallet,
                    type: roundStateInfo._type,
                    finish: isFinished,
                    burnable: roundStateInfo._burnable,
                    dateStart: startDate,
                    dateLimit: endDate,
                    totalReserve: web3.utils.fromWei(dynamicInfo[0]),
                    tokensDynamicAmount: web3.utils.fromWei(dynamicInfo[1]),
                    open: dynamicInfo[2],
                    lockedTokens: web3.utils.fromWei(lockedTokens),
                    index: index,
                    isParticipated: isParticipated,
                    lockup: parseInt(roundStateInfo._lockup),
                    vestingDays: parseInt(roundStateInfo._duration),
                    vestingPeriod: parseInt(roundStateInfo._durationCount),
                    vestingAddrr: vestingAddr,
                    depositedAmount: web3.utils.fromWei(depositedAmount),
                    isCancelled: isCancelled,
                    isWhitelisted: isUserWhitelisted,
                    myShare: web3.utils.fromWei(myShare),
                    vestedShare: web3.utils.fromWei(vestedShare),
                    balanceToClaim: web3.utils.fromWei(balanceToClaim),
                    vestingContract: vestingContract,
                    token: token,
                    nextVestingDate:nextVestingDate,
                    vestingNextTimestamp: vestingStartTimestamp,
                    veting: vesting,
                    startTimestamp: roundStateInfo._startTimestamp,
                    timeout: timeout
                }   
                let data = state.salesData
                
                data[0] = obj
                commit("SET_SALES_DATA", data)

                if(router.currentRoute.name != 'home' || state.salesData.length == 1)
                {
                    commit("SET_CURRENT_ROUND", obj)
                }
                if(state.isTransaction && data.length != 1 && router.currentRoute.name == 'home')
                {
                    commit('SET_IS_TRANSACTION', false)
                    await dispatch('fetchSaleData').then(async()=>{
                        await dispatch('fetchTokenData')
                    })
                }
                commit("SET_IS_LOADED", true)

                commit("SET_IS_FETCHED", true)

            }
            catch(err)
            {
                state.errors.push(err)
                console.log(err)
            }
        },
        async getTransaction({ commit, state })
        {

        },
        async connect({ dispatch, commit, state }, payload)
        {
            const web3 = new Web3(payload.provider)
            state.web3 = web3
            state.provider = payload.provider
            const provider = payload.provider
            commit('SET_WALLET_MODAL', false)
            commit('SET_PROVIDER', provider)
            let acc = []
            if(!state.walletWasDisconnected)
            {
                acc = await web3.eth.getAccounts((error,result) => {
                    if(error || result.length == 0)
                    {
                        console.log(error)
                    }
                    else
                    {
                        let transactions = JSON.parse(window.localStorage.getItem(result[0])) || []
                        commit('SET_TRANSACTIONS', transactions.reverse())
                        commit('SET_USER', result[0])

                    }
                });
            }
            
            const rightChainId = process.env.VUE_APP_RIGHT_CHAIN_ID
            let chainId = await web3.eth.net.getId((error, result)=>{
                if (error || result == 0) {
                    return false;
                } else {
                    commit('SET_CURRENT_CHAIN_ID', Number(result).toString())
                }
            })
            if(!state.walletWasDisconnected && acc.length!= 0 && rightChainId === Number(chainId).toString() && !state.accountWasChanged)
            {
                const abi = saleAbi.abi
                const erc20 = ERC20.abi
    
                const csrContract = new web3.eth.Contract(erc20, csrAddress)
                const saleContract = new web3.eth.Contract(abi, saleContractAddress)
                commit('SET_CONTRACTS', [csrContract, saleContract])
                commit('SET_CONTRACT_ADDRESSES', [csrAddress, saleContractAddress])
    
                commit('SET_IS_LOADED', false)
                await dispatch('fetchSaleData').then(async()=>{
                    await dispatch('fetchTokenData')
                })
            }
            
            provider.on("accountsChanged", async (accounts) => {

                if (accounts.length > 0 && !state.walletWasDisconnected) {
                    commit('SET_CURRENT_ROUND', {})
                    commit('SET_ACCOUNT_WAS_CHANGED', true)
                    commit('SET_IS_LOADED', false)
                    commit('SET_USER', accounts[0])
                    let transactions = JSON.parse(window.localStorage.getItem(accounts[0])) || []
                    commit('SET_TRANSACTIONS', transactions.reverse())
                    commit("SET_SALES_DATA", [])

                    if(router.currentRoute.name !== 'home')
                    {
                      router.push({name: "home"})
                
                    }
                    else
                    {
                        await dispatch('fetchSaleData').then(async()=>{
                            await dispatch('fetchTokenData')
                        })
                    }

                    
    
                    
                    commit('SET_ACCOUNT_WAS_CHANGED', false)
                }
                else
                {
                    commit('SET_CURRENT_ROUND', {})
                    commit('SET_USER', null)
                    commit('SET_IS_LOADED', true)
                    commit("SET_SALES_DATA", [])
                    }
            });
            provider.on('chainChanged', (chainId) =>{
                commit('SET_CURRENT_CHAIN_ID', Number(chainId).toString())
            })
            provider.on('disconnect', (error) =>{
                commit('SET_CURRENT_ROUND', {})
                commit('SET_USER', null)
                commit('SET_IS_LOADED', true)
                commit("SET_SALES_DATA", [])

            })
        }
    }


})

export default store