<template>
  <div id="app">
    <router-view :key="$route.fullPath"/> 
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  created:async function()
  {
    if(this.$route.name !== 'home')
    {
      this.$router.push({name: "home"})

    }
  }
}
</script>

<style>
/* @import './style.css'; */
@font-face {
  font-family: "Inter";
  src: 
   url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap') format("truetype");
}

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
    margin: 0;
    padding: 0;
    font-family: Inter;
}

body,
html {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
}


textarea:focus,
input:focus {
    outline: none;
}

input {
    all: unset;
}

*:focus {
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
