<template>
    <div>
        <!-- <transition name="fade"> -->
        <div class="window" v-if="!isFetched">
            <div class="window__content">
                <img class="window__loader" src="~@/assets/loader.svg">
            </div>
        </div>

        <div class="ps" v-else>
            <div class="ps__container">
                <h2 class="ps__title">Private Sale</h2>
                
                <p class="ps__desc">
                    The cassowary has often been labeled “the world’s most dangerous bird”
                </p>
                <!-- <router-link class="ps__tokens-button home"  to="/">Home</router-link> -->
                <div class="ps__content">
                    <div class="ps__info">
                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Start date:</span>

                            <span class="ps__info-item-value">{{currentRound.dateStart}}</span>
                        </div>

                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Finish date:</span>

                            <span class="ps__info-item-value">{{currentRound.dateLimit}}</span>
                        </div>

                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Total Vesting Time:</span>

                            <span class="ps__info-item-value">{{(currentRound.vestingDays * currentRound.vestingPeriod)/86400}} days ({{(currentRound.lockup)/86400}} days lockup) </span>
                        </div>

                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Tokens to sell:</span>

                            <span class="ps__info-item-value">{{currentRound.tokensAmount}} CSR</span>
                        </div>

                        <div class="ps__info-item">
                            <span class="ps__info-item-name">Total committed:</span>

                            <span class="ps__info-item-value">${{currentRound.totalReserve}}</span>
                        </div>

                        <div class="ps__info-item" v-if="currentRound.tokenLimit != 0">
                            <span class="ps__info-item-name">Funds to raise:</span>

                            <span class="ps__info-item-value">${{currentRound.tokenLimit}}</span>
                        </div>

                        <div class="ps__info-item" v-if="currentRound.price != 0">
                            <span class="ps__info-item-name">Price per CSR:</span>

                            <span class="ps__info-item-value">${{currentRound.price}}</span>
                        </div>
                        <div class="ps__info-item" v-if="currentRound.minAmount != 0">
                            <span class="ps__info-item-name">Min token amount</span>

                            <span class="ps__info-item-value">${{currentRound.minAmount}}</span>
                            
                        </div>
                        <div class="ps__info-item" v-if="currentRound.maxAmount != 0">
                            <span class="ps__info-item-name">Max token amount</span>

                            <span class="ps__info-item-value">${{currentRound.maxAmount}}</span>
                            
                        </div>
                    </div>

                    <form class="ps__deposit" v-if="(!currentRound.finish && !currentRound.isParticipated && !currentRound.isCancelled) || (currentRound.isParticipated && currentRound.depositedAmount == 0 && !currentRound.isCancelled) && !currentRound.timeout">
                        <h4 class="ps__deposit-title">Deposit for investing in round {{currentRound.index + 1}}</h4>

                        <div class="ps__deposit-curr" >
                            <div class="ps__deposit-text amount">Currency</div>

                            <div class="ps__deposit-value" v-on:click="choose">
                                <img class="ps__deposit-logo" v-bind:src="require('../assets/currency-logos/' + pickedToken.nameSvg + '.svg')" v-bind:alt=pickedToken.alt>

                                <div class="ps__deposit-name">{{pickedToken.name}}</div>

                                <div class="ps__deposit-circulation">{{pickedToken.amount}} {{pickedToken.symbol}}</div>
                            </div>

                            <!--  TOKEN DROPDOWN -->
                            <div v-if="isChoose">
                                
                                <div class="ps__deposit-choose">
                                    <div class="ps__deposit-choose-container">
                                        <div class="ps__deposit-choose-back" v-on:click="isChoose = false">
                                            <img src="../assets/back.svg" alt="back"> Back
                                        </div>
                                        <div class="ps__deposit-choose-tokens">
                                            <div v-for="item in stableCoinsData" :key="item.symbol">
                                                <div v-if="item.symbol == pickedToken.symbol">
                                                    <div class="ps__deposit-choose-token-item checked" @click="setCurrentToken(item)">
                                                        <img v-bind:src="require('../assets/currency-logos/' + item.nameSvg + '.svg')" v-bind:alt="(item.alt)">
                                                        <div class="ps__deposit-choose-token-item-name">
                                                            <p>{{item.name}}</p>
                                                            <span>{{item.amount}} {{item.symbol}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="ps__deposit-choose-token-item" @click="setCurrentToken(item)">
                                                        <img v-bind:src="require('../assets/currency-logos/' + item.nameSvg + '.svg')" v-bind:alt="(item.alt)">
                                                        <div class="ps__deposit-choose-token-item-name">
                                                            <p>{{item.name}}</p>
                                                            <span>{{item.amount}} {{item.symbol}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="ps__deposit-amount">
                            <div class="ps__deposit-text amount">Amount</div>
                            <input class="ps__deposit-amount-input"  type="number" step="any" v-model="inputAmount">

                        </div>

                        <div v-if="isLoadingTranscation">
                            <button class="ps__deposit-button loading" type="button">Loading...</button>
                        </div>
                        <div v-else-if="!pickedToken.approved">
                            <button class="ps__deposit-button" type="button" v-on:click="approve">Approve</button>
                        </div>
                        <div v-else-if="inputAmount == 0">
                            <button class="ps__deposit-button disabled" type="button" >Enter amount</button>
                        </div>
                        <div v-else-if="canDeposit">
                            <button class="ps__deposit-button" type="button" v-on:click="deposit">Deposit</button>
                        </div>
                        <div v-else>
                            <button class="ps__deposit-button disabled" type="button">Deposit</button>
                        </div>

                    </form>
                    
                    <div class="ps__deposited" v-else-if="!currentRound.finish && currentRound.depositedAmount != 0">
                        <div class="ps__deposited-title">Deposited funds</div>

                        <div class="ps__deposited-amount">
                            <div class="ps__deposited-text amount">Amount</div>

                            <div class="ps__deposited-amount-value amount">{{currentRound.depositedAmount}} {{currentRound.token}}</div>
                        </div>
                        <div v-if="isLoadingTranscation">
                            <a class="ps__deposited-withdraw loading" href="#">Loading...</a>
                        </div>
                        <div v-else>
                            <a class="ps__deposited-withdraw" href="#" v-on:click="returnDeposit">Withdraw</a>
                        </div>
                    </div>
                    
                        <div class="ps__tokens" v-else-if="currentRound.isParticipated">
                            
                            <div class="ps__tokens-title">Tokens</div>

                            <div class="ps__tokens-item">
                                <span class="ps__tokens-item-name">My share:</span>

                                <span class="ps__tokens-item-value">{{currentRound.myShare}} CSR</span>
                            </div>

                            <div class="ps__tokens-item">
                                <span class="ps__tokens-item-name">Vested share:</span>

                                <span class="ps__tokens-item-value">{{currentRound.vestedShare}} CSR</span>
                            </div>

                            <div class="ps__tokens-item">
                                <span class="ps__tokens-item-name">Balance to claim:</span>

                                <span class="ps__tokens-item-value">{{currentRound.balanceToClaim}} CSR</span>
                            </div>

                            <div class="ps__tokens-item" v-if="parseFloat(currentRound.myShare) > parseFloat(currentRound.vestedShare)">
                                <span class="ps__tokens-item-name">Next vesting date:</span>

                                <span class="ps__tokens-item-value">{{currentRound.nextVestingDate}}</span>
                            </div>
                                <div v-if="isLoadingTranscation">
                                    <button class="ps__tokens-button loading" type="button">Loading...</button>
                                </div>
                                <div v-else-if="currentRound.isCancelled && currentRound.depositedAmount != 0">
                                    <button class="ps__tokens-button" v-on:click="returnDeposit">Return deposit</button>
                                </div>
                                <div v-else-if="(currentRound.balanceToClaim != 0 && currentRound.finish)">
                                    <button class="ps__tokens-button" v-on:click="withdraw">Claim</button>
                                </div>
                                <div v-else-if="parseFloat(currentRound.myShare) - parseFloat(currentRound.vestedShare) != 0">
                                    <button class="ps__tokens-button disabled">Vesting</button>
                                </div>
                                <div v-else>
                                    <button class="ps__tokens-button disabled">Finished</button>
                                </div>
                            <!-- <div v-else-if="currentRound.vestingAddrr === '0x0000000000000000000000000000000000000000'">
                                <button class="ps__tokens-button" type="button" v-on:click="claim">Claim</button>
                            </div>
                            <div v-else-if="currentRound.balanceToClaim == 0">
                                <button class="ps__tokens-button" type="button" disabled>Finished</button>
                            </div>
                            <div v-else>
                                <button class="ps__tokens-button" type="button" v-on:click="withdraw">Withdraw</button>
                            </div> -->

                            
                            <!-- DISABLED -->
                            <!-- <button class="ps__tokens-button" disabled>Finished</button> -->
                        </div>
                </div>
            </div>
        </div>
        <!-- </transition> -->
    </div>
</template>
<script>
import store from '../store/index.js'
import { mapState } from 'vuex'
import ERC20 from '../abis/ERC20.json'
import Loader from '../components/Loader.vue' 
import {ethers} from 'ethers'
export default {
    name:"Sale",
    store,
    components:
    {
        // ModalSuccess,
        // ModalError,
    },
    data()
    {
        return{
            isChoose:false,
            inputAmount: '',
            saleContract: null,
            csrContract: null,
            isApproved: false,
            isLoadingTranscation: false,
            currentToken: null,
            canDeposit:false
        }
    },
    computed:
    {
      ...mapState({
        currentRound: 'currentRound',
        stableCoinsData: 'stableCoinsData',
        pickedToken: 'pickedToken',
        showModalError: 'showModalError',
        showModalSuccess: 'showModalSuccess',
        isFetched: 'isFetched'
      }),

    },
    created: async function()
    {
        this.saleContract = this.$store.state.saleContract
        this.csrContract = this.$store.state.csrContract
    },
    methods:
    {
        choose()
        {
            this.isChoose == false ? this.isChoose = true : this.isChoose = false
        },
        setCurrentToken(item)
        {
            this.$store.commit('SET_PICKED_TOKEN', item)
            this.inputAmount = ''
            this.checkDeposit()
            this.isChoose = false
        },
        async deposit()
        {
            try
            {
                this.isLoadingTranscation = true
                this.$store.commit('SET_IS_TRANSACTION', true)
                // const provider = this.$store.state.provider
                const web3 = this.$store.state.web3
                const token = this.$store.state.pickedToken
                const saleContract = this.$store.state.saleContract
                const user = this.$store.state.user
                // const tokenContract = new ethers.Contract(token.addr, ERC20.abi, provider)
                // const decimals = await tokenContract.decimals()
                const amount = ethers.utils.parseUnits(String(this.inputAmount), parseInt(token.decimals))
                let tx = () => {
                    return new Promise((resolve, reject) => {
                    saleContract.methods.deposit(amount.toString(), token.addr).send({from: user})
                        .on('transactionHash', function(hash) {
                            resolve(hash);
                        })
                        .on('error', function(error){
                            reject(error)
                        });
                    }); 
                }
                let txn = await tx()
                this.$store.dispatch('pushTransaction', txn)
                const receipt= await this.waitTransaction(txn)
                if(receipt)
                {
                    this.$store.commit('SET_SHOW_SUCCESS', true)

                    setTimeout(() => this.$store.commit('SET_SHOW_SUCCESS', false), 2000);
                }
                
                this.isLoadingTranscation = false
                await this.$store.dispatch('fetchCurrentRound')

                this.$store.commit('SET_IS_TRANSACTION', false)

            }
            catch(err)
            {
                console.log(err)
                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_ERROR', true)

                setTimeout(() => this.$store.commit('SET_SHOW_ERROR', false), 2000);


            }

        },
        async withdraw()
        {
            this.isLoadingTranscation = true
            this.$store.commit('SET_IS_TRANSACTION', true)
            try
            {
                // const web3 = this.$store.state.web3
                const user = this.$store.state.user
                const vestingContract = this.$store.state.currentRound.vestingContract
                let tx = () => {
                    return new Promise((resolve, reject) => {
                        vestingContract.methods.claim().send({from: user})
                        .on('transactionHash', function(hash) {
                            resolve(hash);
                        })
                        .on('error', function(error){
                            reject(error)
                        });
                    });
                }
                let txn = await tx()
                this.$store.dispatch('pushTransaction', txn)
                const receipt= await this.waitTransaction(txn)
                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_SUCCESS', true)

                setTimeout(() => this.$store.commit('SET_SHOW_SUCCESS', false), 2000);

                await this.$store.dispatch('fetchCurrentRound')
                
                this.$store.commit('SET_IS_TRANSACTION', false)

            }
            catch(err)
            {
                console.log(err)
                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_ERROR', true)

                setTimeout(() => this.$store.commit('SET_SHOW_ERROR', false), 2000);
                
            }

        },
        async claim()
        {
            // const web3 = this.$store.state.web3
            this.isLoadingTranscation = true
            this.$store.commit('SET_IS_TRANSACTION', true)
            try
            {
                const user = this.$store.state.user
                const currentRoundId = this.$store.state.currentRound.index
                const saleContract = this.$store.state.saleContract
                let tx = () => {
                    return new Promise((resolve, reject) => {
                        saleContract.methods.claim(currentRoundId).send({from: user})
                        .on('transactionHash', function(hash) {
                            resolve(hash);
                        })
                        .on('error', function(error){
                            reject(error)
                        });
                    });
                }
                let txn = await tx()
                this.$store.dispatch('pushTransaction', txn)
                const receipt= await this.waitTransaction(txn)
                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_SUCCESS', true)

                setTimeout(() => this.$store.commit('SET_SHOW_SUCCESS', false), 2000);
                await this.$store.dispatch('fetchCurrentRound')
                this.$store.commit('SET_IS_TRANSACTION', false)

            }
            catch(err)
            {
                console.log(err)

                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_ERROR', true)

                setTimeout(() => this.$store.commit('SET_SHOW_ERROR', false), 2000);
            }
        },
        async returnDeposit()
        {
            this.isLoadingTranscation = true
            this.$store.commit('SET_IS_TRANSACTION', true)
            try
            {
                const user = this.$store.state.user
                const currentRoundId = this.$store.state.currentRound.index
                const saleContract = this.$store.state.saleContract
                let tx = () => {
                    return new Promise((resolve, reject) => {
                        saleContract.methods.returnDeposit(currentRoundId).send({from: user})
                        .on('transactionHash', function(hash) {
                            resolve(hash);
                        })
                        .on('error', function(error){
                            reject(error)
                        });
                    });
                }
                let txn = await tx()
                this.$store.dispatch('pushTransaction', txn)
                const receipt= await this.waitTransaction(txn)
                // this.showModalSuccess = true
                // setTimeout(() => this.showModalSuccess = false, 2000);
                if(receipt)
                {
                    this.$store.commit('SET_SHOW_SUCCESS', true)

                    setTimeout(() => this.$store.commit('SET_SHOW_SUCCESS', false), 2000);

                }
                this.isLoadingTranscation = false
                await this.$store.dispatch('fetchCurrentRound')
                
                this.$store.commit('SET_IS_TRANSACTION', false)
            }
            catch(err)
            {
                console.log(err)

                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_ERROR', true)

                setTimeout(() => this.$store.commit('SET_SHOW_ERROR', false), 2000);
            }

        },
        async approve()
        {
            this.isLoadingTranscation = true
            this.$store.commit('SET_IS_TRANSACTION', true)
            try
            {
                const web3 = this.$store.state.web3
                const maxInt= new web3.utils.BN("2").pow(new web3.utils.BN("256").sub(new web3.utils.BN("1")));
                const token = this.$store.state.pickedToken
                const tokenContract = new web3.eth.Contract(ERC20.abi, token.addr)
                const user = this.$store.state.user
                this.currentToken= token
                let tx = () => {
                    return new Promise((resolve, reject) => {
                        tokenContract.methods.approve(this.$store.state.saleContractAddr, maxInt).send({from: user})
                        .on('transactionHash', function(hash) {
                            resolve(hash);
                        })
                        .on('error', function(error){
                            reject(error)
                        });
                    });
                }
                let txn = await tx()
                this.$store.dispatch('pushTransaction', txn)
                const receipt= await this.waitTransaction(txn)
                this.$store.commit('SET_SHOW_SUCCESS', true)
                this.isLoadingTranscation = false
                this.$store.commit('SET_IS_TRANSACTION', false)

                setTimeout(() => this.$store.commit('SET_SHOW_SUCCESS', false), 2000);

                if(receipt)
                {
                    this.currentToken.approved = true
                    
                    this.$store.commit('SET_APPROVE', this.currentToken)
                }
                this.inputAmount = ''
                this.checkDeposit()

            }
            catch(err)
            {
                console.log(err)

                this.isLoadingTranscation = false
                this.$store.commit('SET_SHOW_ERROR', true)

                setTimeout(() => this.$store.commit('SET_SHOW_ERROR', false), 2000);
            }
            // await this.$store.dispatch('fetchTokenData')
        },
        async sleep (milliseconds)
        {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },
        async waitTransaction (txnHash)
        {
            const web3 = this.$store.state.web3
            const expectedBlockTime = 1000; 
            let transactionReceipt = null
            while (transactionReceipt == null)
            {
                transactionReceipt = await web3.eth.getTransactionReceipt(txnHash);
                await this.sleep(expectedBlockTime)
            }
            return transactionReceipt
        },
        
        checkDeposit(balance, totalReserve, maxTokens)
        {
            let now = Date.now() / 1000
            this.canDeposit = (parseFloat(this.inputAmount) <= parseFloat(balance) && 
            ((parseFloat(this.inputAmount)>=parseFloat(this.currentRound.minAmount) &&  
            parseFloat(this.inputAmount)<=parseFloat(this.currentRound.maxAmount)) || this.currentRound.maxAmount == 0) &&
            parseFloat(now) >= parseFloat(this.currentRound.startTimestamp) &&(
            parseFloat(this.inputAmount) <= parseFloat(maxTokens) - parseFloat(totalReserve) || maxTokens == 0)
            )
        }
    },
    watch:
    {
        inputAmount:async function()
        {
            const web3 = this.$store.state.web3
            const tokenContract = new web3.eth.Contract(ERC20.abi, this.pickedToken.addr)
            const user = this.$store.state.user
            const saleContract = this.$store.state.saleContract
            const currentRoundId = this.$store.state.currentRound.index
            let balance = await tokenContract.methods.balanceOf(user).call()
            let dynamicInfo = await saleContract.methods.getRoundDynamicInfromation(currentRoundId).call()

            let totalReserve = web3.utils.fromWei(dynamicInfo[0])
            let maxTokens = this.currentRound.tokenLimit
            // balance = web3.utils.fromWei(balance)
            balance = ethers.utils.formatUnits(balance.toString(), parseInt(this.pickedToken.decimals))
            this.checkDeposit(balance, totalReserve, maxTokens)
        }
    }
}
</script>
<style scoped>
        .ps {
            padding-top: 24px;
            width: 100%;
            
        }
        .ps button{
            transition: all 0.25s ease;
        }
        .ps__container {
            max-width: 400px;
            margin: 0 auto;
        }
        
        .ps__title {
            font-weight: 600;
            font-size: 32px;
            line-height: 30px;
            text-align: center;
            color: #012535;
            /* padding-top:26px;
            padding-bottom:12px; */
        }
        
        .ps__desc {
            max-width: 303px;
            margin: 0 auto 40px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #012535;
        }
        
        .ps__content {
            display: flex;
            flex-direction: column;
        }
        
        .ps__info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
        }
        
        .ps__info-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
            text-align: center;
        }
        
        .ps__info-item:not(:last-child) {
            margin-bottom: 10px;
        }
        
        .ps__info-item-name {
            width: 100%;
            margin-right: 12px;
            text-align: right;
        }
        
        .ps__info-item-value {
            width: 100%;
            text-align: left;
        }
        
        .ps__deposit-title {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .ps__deposit-curr {
            position: relative;
            width: 100%;
            height: 62px;
            margin-bottom: 12px;
            background: #FFFFFF;
            border: 1px solid #CBCDCE;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
        }
        
        .ps__deposit-curr:after {
            content: '';
            position: absolute;
            top: 26px;
            right: 23px;
            width: 6px;
            height: 10px;
            background-image: url(~@/assets/chevron.svg);
        }
        
        .ps__deposit-text {
            margin: 8px 0 4px 18px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #72868B
        }
        

        .ps__deposit-value {
            margin-left: 18px;
            display: flex;
            align-items: center;
        }
        
        .ps__deposit-logo {
            width: 22px;
            height: 22px;
            margin-right: 6px;
        }
        
        .ps__deposit-name {
            margin-right: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
        }
        
        .ps__deposit-circulation {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #8E98A5;
        }
        
        .ps__deposit-amount {
            position: relative;
            width: 100%;
            height: 62px;
            margin-bottom: 20px;
            background: #FFFFFF;
            border: 1px solid #CBCDCE;
            box-sizing: border-box;
            border-radius: 8px;
        }
        
        .ps__deposit-amount-input {
            text-align: left;
            padding-left: 18px;
            display: table-cell;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
        }
        
        .ps__deposit-amount-curr {
            position: absolute;
            top: 30px;
            left: 110px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
        }
        
        .ps__deposit-button {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            background: #218CEF;
            border-radius: 32px;
        }
        
        .ps__deposit-button:hover {
            background: rgba(33, 140, 239, 0.5);
        }
        
        @media (min-width: 1000px) {
            .ps__container {
                max-width: 740px;
                padding-top:62px;
            }
            .ps__content {
                flex-direction: row;
                justify-content: space-between;
            }
            .ps__info {
                width: 100%;
                margin-right: 44px;
            }
            .ps__deposit {
                width: 100%;
            }
        }
.ps__deposit-choose {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            z-index: 1;
        }
        
        .ps__deposit-choose-container {
            max-width: 400px;
            margin: 0 auto;
            padding: 0 16px;
        }
        
        .ps__deposit-choose-back {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            padding-top: 80px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #012535;
        }
        
        .ps__deposit-choose-back img {
            width: 18px;
            height: 14px;
            display: block;
            margin-right: 10px;
        }
        
        .ps__deposit-choose-tokens {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        
        .ps__deposit-choose-token-item {
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 0;
        }
        
        .ps__deposit-choose-token-item:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        
        .ps__deposit-choose-token-item.checked::after {
            content: '';
            position: absolute;
            top: 26px;
            right: 19px;
            width: 18px;
            height: 13.5px;
            background-image: url(~@/assets/check.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        .ps__deposit-choose-token-item img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
        }
        
        .ps__deposit-choose-token-item-name p {
            display:flex;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #012535;
        }
        
        .ps__deposit-choose-token-item-name span {
            display:flex;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #72868B;
        }
        
        @media (min-width: 1000px) {
            .ps__deposit-choose {
                position: absolute;
                top: 70px;
                left: -1px;
                height: auto;
                border: 1px solid #CBCDCE;
                border-radius: 8px;
            }
            .ps__deposit-choose-back {
                display: none;
            }
            .ps__deposit-curr:after {
                transform: rotate(90deg);
            }
        }

        .ps__deposited {
            width: 100%;
        }
        
        .ps__deposited-title {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .ps__deposited-amount {
            position: relative;
            width: 100%;
            height: 62px;
            margin-bottom: 20px;
            background: #EBEBEB;
            border: 1px solid #CBCDCE;
            box-sizing: border-box;
            border-radius: 8px;
        }
        
        .ps__deposited-text {
            margin: 8px 0 4px 18px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #72868B
        }
        
        .ps__deposited-amount-value {
            padding-left: 18px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
        }
        
        .ps__deposited-withdraw {
            display: flex;
            justify-content: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #218CEF;
            text-decoration: none;
            cursor:pointer;
        }

        .ps__tokens {
            width: 100%;
            /* flex-basis: 50%;
            flex-grow: 0; */
        }
        
        .ps__tokens-title {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .ps__tokens-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #012535;
        }
        
        .ps__tokens-item {
            margin-bottom: 10px;
        }
        
        .ps__tokens-item-name {
            width: 100%;
            margin-right: 12px;
            text-align: right;
        }
        
        .ps__tokens-item-value {
            width: 100%;
            text-align: left;
        }
        
        .ps__tokens-button {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            color: #FFFFFF;
            background: #218CEF;
            border-radius: 32px;
            transition: all 0.25s ease;
        }
        
        .ps__tokens-button:hover {
            background: rgba(33, 140, 239, 0.5);
        }
        
        .ps__tokens-button:disabled,
        .ps__tokens-button[disabled] {
            background: rgba(33, 140, 239, 0.5);
        }
        .amount
        {
            text-align:left;
        }
        .home{
            width:100px;
            margin:20px;
            margin-left:auto;
            margin-right:auto;
        }
        .loading
        {
            transition: none;
            cursor: default;
        }
        .fade-enter-active, .fade-leave-active {
        transition: opacity .25s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
        }
  .disabled
  {
      background: #dddddd;
      cursor:default;
  }
  .disabled:hover
  {
      background: #dddddd;
  }

          @-webkit-keyframes rotating {
            from {
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        @keyframes rotating {
            from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        .window {
            margin: auto;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;

            display: flex;
            align-items: flex-end;
            /* background: rgba(1, 37, 53, 0.8); */
            background: #fff;
        }
        
        .window__content {
            position: relative;
            left: 0;
            right: 0;
            bottom: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            max-width: 400px;
            height: 286px;
            margin: 0 auto;
            background: #fff;
            border-radius: 9px;
        }
        
        .window__loader {
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
        }
        
        @media (min-width: 768px) {
            .window {
                align-items: center;
            }
        }
</style>