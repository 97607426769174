<template>
    <div class="window">
        <div class="window__content">
            <img class="window__loader" src="~@/assets/loader.svg">
        </div>
    </div>
</template>

<script>
export default {
    name:"Loader"
}
</script>

<style scoped>
        @-webkit-keyframes rotating {
            from {
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        @keyframes rotating {
            from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        .window {
            margin: auto;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            display: flex;
            align-items: flex-end;
            /* background: rgba(1, 37, 53, 0.8); */
            background: #fff;
        }
        
        .window__content {
            position: relative;
            left: 0;
            right: 0;
            bottom: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            max-width: 400px;
            height: 286px;
            margin: 0 auto;
            background: #fff;
            border-radius: 9px;
        }
        
        .window__loader {
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
        }
        
        @media (min-width: 768px) {
            .window {
                align-items: center;
            }
        }
</style>