import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Round from '../views/Round.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
      path: '/:id',
      name: 'round',
      component: Round
    },
]

const router = new VueRouter({
  mode: '',
  routes
})

export default router
