<template>
    <div>
        <div class="header">
            <div v-if="!home">
                <div class="header__logo" v-on:click="goHome">
                    <div class="header__logo-img"></div>
                    <div class="header__logo-title">CASSOWARY</div>
                </div>
            </div>
            <div v-else>
                <div class="header__logo disabled">
                    <div class="header__logo-img"></div>
                    <div class="header__logo-title">CASSOWARY</div>
                </div>
            </div>
            <div class="header__menu" id="header__menu">
                <a href="#">Docs</a>
                <a href="#">Blog</a>
                <a href="#">
                    <div class="header__menu-user" v-on:click="showWallet">
                        <div class="header__menu-username">
                            {{user}}
                        </div>
                        <div class="header__menu-userwallet"></div>
                    </div>
                </a>
            </div>
            <div class="header__hamburger" id="hamburger" v-on:click="showWallet">
                <span class="header__hamburger-item"></span>
                <span class="header__hamburger-item"></span>
                <span class="header__hamburger-item"></span>
            </div>
        </div>
    </div>
</template>

<script>
import store from '../store/index.js'
import { mapState } from 'vuex'

export default {
    name: "Header",
    store,
    props: ['home'],
    computed:
    {
      ...mapState({  
        user: 'user',
      })
    },
    methods:
    {
        showWallet()
        {
            if(this.$store.state.showWallet == false)
            {
                this.$store.commit('SET_SHOW_WALLET', true)
            }
            else
            {
                this.$store.commit('SET_SHOW_WALLET', false)
            }
        },
        goHome()
        {
            this.$store.commit('SET_CURRENT_ROUND', {})
            this.$store.commit('SET_IS_LOADED', false)
            if(this.$route.name !== 'home')
            {
                this.$router.push({name: "home"})

            }
        }
    }
}
</script>

<style >
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
    z-index: 3;
}

.header__logo {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.header__logo-img {
    width: 32px;
    height: 32px;
    background-image: url(~@/static/logo.png);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.header__logo-title {
    margin-left: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: #012535;
    text-transform: uppercase;
}

.header__hamburger {
    display: flex;
    flex-direction: column;
    margin-right: 27px;
    transition: all 0.25s ease;
}

.header__hamburger-item {
    display: flex;
    width: 18px;
    height: 2px;
    background: #101A31;
    border-radius: 1px;
    transition: all 0.25s ease;
}

.header__hamburger-item:not(:last-child) {
    margin-bottom: 4px;
}

.header__hamburger_open {
    transition: all 0.25s ease;
}

.header__hamburger_open .header__hamburger-item:nth-child(1) {
    margin-bottom: -2px;
    transform: rotate(45deg);
}

.header__hamburger_open .header__hamburger-item:nth-child(2) {
    display: none;
}

.header__hamburger_open .header__hamburger-item:nth-child(3) {
    margin-bottom: 0;
    transform: rotate(-45deg);
}

.header__menu {
    position: fixed;
    top: 56px;
    width: 100%;
    height: 0px;
    display: none;
    flex-direction: column;
    background-color: #fff;
    transition: height 0.25s ease;
}

.header__menu a {
    text-decoration: none;
    font-size: 18px;
    color: #012535;
}

.header__menu_open {
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.header__menu-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 36px;
    background: #F7F7F7;
    border-radius: 30px;
}

.header__menu-username {
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    margin-right: 12px;
    width: 68px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header__menu-userwallet {
    width: 24px;
    height: 19.5px;
    background-image: url(~@/static/header-user-wallet.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media (min-width: 768px) {
    .header__logo {
        margin-right: 40px;
        cursor: pointer;
    }
    .header__menu {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        flex: 1;
        height: unset;
        width: unset;
        top: unset;
    }
    .header__hamburger {
        display: none;
    }
    .header__menu a:nth-child(1) {
        margin-right: 20px;
    }
    .header__menu a:nth-child(3) {
        margin-left: auto;
        margin-right: 16px;
    }
}
.disabled
{
    cursor: default;
}

</style>