<template>
  <div>
        <div v-if="showModalSuccess">
            <ModalSuccess/>
        </div>
        <div v-else-if="showModalError">
            <ModalError/>
        </div>
        <Start v-if="isLoaded"/> 
      
        <Loader v-else/>
  </div>
</template>

<script>
// import Header from '../components/Header.vue' 
// import Wallet from '../components/Wallet.vue' 

// import Sale from '../components/Sale.vue' 
import Start from '../components/Start.vue' 
import Loader from '../components/Loader.vue' 

import Web3 from 'web3'
import store from '../store/index.js'
import { mapState } from 'vuex'
import saleAbi from '../abis/PrivateSale.json'
import ERC20 from '../abis/ERC20.json'
import ModalSuccess from '../components/ModalSuccess.vue'  
import ModalError from '../components/ModalError.vue'  

export default {
    name: 'Home',
    components: {
      // Header,
      // Sale,
      Start,
      // Wallet,
      Loader,
      ModalSuccess,
      ModalError
    },
    store,
    data()
    {
      return{
          isMetamaskConnected:false,
          isMetamaskInstalled: false,
          account: '',
          inWhiteList: false,
          saleContract: null,
          csrContract: null,
          rightChainid: process.env.VUE_APP_RIGHT_CHAIN_ID
      }
    },
    computed:
    {
      ...mapState({
          
        web3: null,
        user:"user",
        showWallet: 'showWallet',
        currentRound: 'currentRound',
        isLoaded: "isLoaded",
        walletWasDisconnected: 'walletWasDisconnected',
        provider: 'provider',
        currentChainid: 'currentChainid',
        fetchingProblem: 'fetchingProblem',
        showModalError: 'showModalError',
        showModalSuccess: 'showModalSuccess',

      })
    },
    created: async function()
    {
        // const web3 =  new Web3(window.ethereum)
        if (window.location.protocol != 'https:')
        {
          window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length)
        }
        
        if(this.$store.state.salesData.length != 1 && !this.$store.state.isTransaction)
        {
          this.$store.commit('SET_CURRENT_ROUND', {})
          this.$store.commit('SET_IS_LOADED', false)
        }
        
        if(window.ethereum != undefined && !this.user)
        {
          await this.$store.dispatch('connect', {provider: window.ethereum})
        }
        else if(this.user)
        {
                    this.$store.commit('SET_IS_LOADED', false)

            await this.$store.dispatch('fetchSaleData').then(async()=>{
                await this.$store.dispatch('fetchTokenData')
            })

          
        }
        // this.$store.commit('SET_WEB3', web3)
        const data = this.$store.state.salesData
        const web3 = this.$store.state.web3
        const wasChanged = this.$store.state.accountWasChanged
        // if((data.length === 0 && web3 && this.currentChainid == this.rightChainid))
        // {
        //   await this.$store.dispatch('fetchSaleData').then(async()=>{
        //       await this.$store.dispatch('fetchTokenData')
        //   })  
        // }
        this.$store.commit('SET_IS_LOADED', true)
    },
    mounted:async function () 
    {
        // await this.checkMetamaskConnect()

        // window.ethereum.on('accountsChanged', (accounts) => {
        //   if(this.user != accounts[0] && !this.walletWasDisconnected)
        //   {
        //       this.account = accounts[0]
        //       this.$store.commit('SET_USER', this.account)
        //       this.fetchData()
        //     //   this.accountShorted = accounts[0].slice(0, 6) + '...' + accounts[0].slice(accounts[0].length - 4, accounts[0].length)
        //   }
          
        //   if(this.account == this.ownerAccount)
        //   {
        //       this.isOwner = true
        //   }
        //   else 
        //   {
        //       this.isOwner = false
        //   }

        // });
        // window.ethereum.on('chainChanged', (chainId) =>{
        //   this.$store.commit('SET_CURRENT_CHAIN_ID', chainId)
        //   this.currentChainId = Number(chainId).toString()
        // })
        
        // this.$store.commit('SET_USER', this.account)

    },
    methods:
    {
        async checkMetamaskConnect()
        {
            if(window.ethereum)
            {
                const web3 =  new Web3(window.ethereum)
                this.isMetamaskInstalled = true
                const isMetaMaskConnected = async () => {
                    web3.eth.getAccounts((error,result) => {
                            if (error || result.length == 0 || this.walletWasDisconnected) {
                                this.isMetamaskConnected = false
                                return false;
                            } else {
                                this.account = result[0]
                                this.$store.commit('SET_USER', this.account)
                                this.isMetamaskConnected = true
                                return true;
                            }
                    });
                }
                await isMetaMaskConnected()
            }
            else
            {
              this.isMetamaskInstalled = false
            }

        },
        // async fetchData()
        // {
        //   this.$store.commit('SET_IS_LOADED', false)

        //   await this.$store.dispatch('fetchSaleData').then(async()=>{
        //       await this.$store.dispatch('fetchTokenData')
        //   })
        // },
    }
}
</script>

<style scoped>

@font-face {
  font-family: "Inter";
  src: 
   url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap') format("truetype");
}

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
    margin: 0;
    padding: 0;
    font-family: Inter;
}

body,
html {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
}


textarea:focus,
input:focus {
    outline: none;
}

input {
    all: unset;
}

*:focus {
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>