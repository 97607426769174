<template>

    <div>
        <div class="window">

            <div class="window__content window__content_success">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" width="48" height="48"><path fill="#fff" fill-rule="evenodd" d="M41.345 10.52a2 2 0 0 1 .135 2.825L20.774 36.121a1 1 0 0 1-1.447.035L6.586 23.414a2 2 0 1 1 2.828-2.828l10.517 10.517 18.59-20.448a2 2 0 0 1 2.824-.135Z" clip-rule="evenodd"/></svg>

                <span class="window__alert-text">Transaction successfully completed</span>

                <button class="window__button-close" v-on:click="closeModal">Close</button>
            </div>

        </div>

    </div>    

</template>

<script>
import store from '../store/index.js'

export default {
    
    name:"ModalSuccess",
    store,
    methods:
    {
        closeModal()
        {
            this.$store.commit('SET_SHOW_SUCCESS', false)
        }
    }
}
</script>

<style scoped>
        .window {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top:0;
            display: flex;
            align-items: flex-end;
            background: rgba(1, 37, 53, 0.6);

            z-index: 999;
        }
        
        .window__content {
            position: relative;
            left: 0;
            right: 0;
            bottom: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            max-width: 400px;
            height: 286px;
            margin: 0 auto;
            background: #fff;
            border-radius: 9px;
            margin-bottom: 15%;
        }
        
        .window__content_success {
            background: #05A859;
        }
        
        .window__alert-text {
            margin: 20px 0 44px;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
        }
        
        .window__button-close {
            width: 90%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E3E3E3;
            border-radius: 32px;
            font-weight: bold;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
            transition: all 0.25s ease;
        }
        
        .window__button-close:hover {
            background: #EBEBEB;
        }
        
        @media (min-width: 768px) {
            .window {
                align-items: center;
            }
        }

</style>