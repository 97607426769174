<template>
    
    <div>
        <div class="wallet">
            <button class="wallet__close"><img src="../assets/close.svg" alt="close wallet" v-on:click="closeWallet"></button>

            <div class="wallet__container">
                <div class="wallet__title">Wallet</div>

                <div class="wallet__value">
                    <span>{{user}}</span>
                    <button class="wallet__value-copy" ref="user" :value="user">
                        <img src="../assets/copy.svg" alt="copy wallet">
                    </button>
                </div>

                <div class="wallet__buttons">
                    <button class="wallet__button" v-on:click="openAddress">View on explorer <img src="../assets/open.svg" alt="open"></button>
                    <button class="wallet__button wallet__button_disconnect" v-on:click="disconnect">Disconnect</button>
                </div>

                <h4 class="wallet__transactions">Transactions</h4>

                <div class="wallet__transactions-items">
                    <div class="wallet__transactions-item" v-for="item in transactions" :key ="item">
                        <div class="wallet__transactions-item-value">
                            <!-- <span>Replay</span>
                            <span>-0.000032312324124124123123</span>
                            <span>USDT</span> -->
                            <span>{{item.slice(0, 6) + '...' + item.slice(item.length - 4, item.length)}}</span>
                        </div>
                        
                        <button class="wallet__button" @click="openTransaction(item)">Explorer <img src="../assets/open.svg" alt="open" ></button>
                    </div>

                    <!-- <div class="wallet__transactions-item">
                        <div class="wallet__transactions-item-value">
                            <span>Replay</span>
                            <span>-0.000032312324124124123123</span>
                            <span>USDT</span>
                        </div>

                        <button class="wallet__button">Explorer <img src="../assets/open.svg" alt="open"></button>
                    </div>

                    <div class="wallet__transactions-item">
                        <div class="wallet__transactions-item-value">
                            <span>Replay</span>
                            <span>-0.000032312324124124123123</span>
                            <span>USDT</span>
                        </div>

                        <button class="wallet__button">Explorer <img src="../assets/open.svg" alt="open"></button>
                    </div> -->
                </div>



                <div class="wallet__links">
                    <a href="#" class="wallet__link">Docs <img src="../assets/open.svg" alt="open"></a>
                    <a href="#" class="wallet__link">Blog <img src="../assets/open.svg" alt="open"></a>
                </div>
            </div>
         </div>
    </div>
</template>

<script>
import store from '../store/index.js'
import { mapState } from 'vuex'

export default {
    name:"Wallet",
    store,
    computed:
    {
      ...mapState({
          
        web3: null,
        user:"user",
        showWallet: 'showWallet',
        currentRound: 'currentRound',
        isLoaded: "isLoaded",
        transactions:"transactions"
      })
    },
    methods:
    {
        closeWallet()
        {
            this.$store.commit('SET_SHOW_WALLET', false)
        },
        disconnect()
        {
            this.$store.commit('SET_WALLET_WAS_DISCONNECTED', true)
            this.$store.commit('SET_SHOW_WALLET', false)
            this.$store.commit('SET_CURRENT_ROUND', {})
            this.$store.commit('SET_USER', null)
            if(this.$route.name !== 'home')
            {
                this.$router.push({name: "home"})

            }
        },
        openAddress()
        {
            window.open("https://etherscan.io/address/" + this.user , "_blank");    
        },
        openTransaction(tx)
        {
            window.open("https://etherscan.io/tx/" + tx , "_blank");    
        },
        // copyAddress()
        // {
        //     this.$refs.user.focus();
        //     document.execCommand('copy');

        // }
    }
}
</script>

<style scoped>
        .wallet {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            z-index: 4;
        }
        
        .wallet__close {
            position: absolute;
            top: 16px;
            right: 16px;
        }
        
        .wallet__container {
            max-width: 400px;
            margin: 0 auto;
            padding: 0 16px;
        }
        
        .wallet__title {
            margin-bottom: 16px;
            padding-top: 26px;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #012535;
        }
        
        .wallet__value {
            position: relative;
            height: 36px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            border-radius: 30px;
        }
        
        .wallet__value span {
            padding-left: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #012535;
        }
        
        .wallet__value-copy {
            position: absolute;
            top: 8px;
            right: 8px;
        }
        
        .wallet__buttons {
            display: flex;
            margin-bottom: 16px;
        }
        
        .wallet__button {
            display: flex;
            align-items: center;
            padding: 4px 10px;
            background: #E3E3E3;
            border-radius: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #012535;
            transition: all 0.25s ease;
        }
        
        .wallet__button:hover {
            background: #EBEBEB;
        }
        
        .wallet__button img {
            margin-left: 10px;
        }
        
        .wallet__button.wallet__button_disconnect {
            margin-left: 12px;
            background: #D94848;
            color: #FFFFFF;
        }
        
        .wallet__button.wallet__button_disconnect:hover {
            background: #E27817;
        }
        
        .wallet__transactions {
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #012535;
        }
        
        .wallet__transactions-items {
            overflow-y: scroll;
            height: 400px;
            margin-bottom: 16px;
        }
        
        .wallet__transactions-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
        }
        
        .wallet__transactions-item:not(:last-child) {
            border-bottom: 1px solid #E3E3E3;
            margin-bottom: 10px;
        }
        
        .wallet__transactions-item-value {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            color: #012535;
        }
        
        .wallet__transactions-item-value span {
            display: block;
        }
        
        .wallet__transactions-item-value span:not(:last-child) {
            margin-bottom: 10px;
        }
        
        .wallet__links {
            margin-top: 26px;
        }
        
        .wallet__link {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            color: #012535;
            text-decoration: none;
        }
        
        .wallet__link:not(:last-child) {
            margin-bottom: 26px;
        }
        
        .wallet__link img {
            margin-left: 8px;
        }
        
        @media (min-width: 768px) {
            .wallet {
                left: unset;
                right: 0;
                max-width: 375px;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                z-index: 2;
            }
            .wallet__close {
                display: none;
            }
            .wallet__title {
                padding-top: 88px;
            }
        }

</style>