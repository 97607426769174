<template>
    <div>
        <div v-if="showModalSuccess">
            <ModalSuccess/>
        </div>
        <div v-else-if="showModalError">
            <ModalError/>
        </div>
        <div>
            <Header :home="false"/>
            <div v-if="showWallet">
                <Wallet/> 
            </div>
            <transition name="fade">
            <Sale/>
            </transition>
        </div>
    </div>
</template>

<script>
import Sale from '../components/Sale.vue'  
import Header from '../components/Header.vue' 
import Wallet from '../components/Wallet.vue' 
import store from '../store/index.js'
import { mapState } from 'vuex'
import ModalSuccess from '../components/ModalSuccess.vue'  
import ModalError from '../components/ModalError.vue'  

export default {
    components:
    {
        Sale,
        Header,
        Wallet,
        ModalError,
        ModalSuccess,
        // Loader

    },
    store,
        computed:
    {
    ...mapState({
          
        // web3: null,
        // user:"user",
        showWallet: 'showWallet',
        // currentRound: 'currentRound',
        // isLoaded: "isLoaded",
                showModalError: 'showModalError',
        showModalSuccess: 'showModalSuccess',
        isFetched: 'isFetched'

      })
    },

}
</script>

<style scoped>
@font-face {
  font-family: "Inter";
  src: 
   url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700&display=swap') format("truetype");
}

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
    margin: 0;
    padding: 0;
    font-family: Inter;
}

body,
html {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
}


textarea:focus,
input:focus {
    outline: none;
}

input {
    all: unset;
}

*:focus {
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>