<template>
    <div>
        <div v-if="showWeb3Modal">
            
        </div>
        <div class="window">
            <div class="window__content">
                <div class="window__title">Connect Wallet</div>
                <div class="window__choose">
                    <button>
                        <img src="../assets/metamask.svg" alt="Metamask" v-on:click="connectMetamask">
                    </button>

                    <button>
                        <img src="../assets/walletconnect.svg" alt="WalletConnect" v-on:click="connectWalletConnect">
                    </button>

                    <button>
                        <img src="../assets/trustwallet.svg" alt="TrustWallet" v-on:click="connectMetamask">
                    </button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import store from '../store/index.js'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from 'web3'
// import {web3Modal} from "./config/mixins";
export default {
    name:"WalletPickModal",
    store,
    components:
    {
    },
    data()
    {
        return{
            showWeb3Modal: false,
            providerOptions: null,
            chainId: process.env.VUE_APP_RIGHT_CHAIN_ID,
            infuraId: process.env.VUE_APP_INFURA_ID
        }
    },
    computed:
    {
        web3Modal()
        {
            return this.$store.state.web3Modal
        }
    },
    methods:
    {
        async connectMetamask()
        {
            try
            {  
                let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const walletWasDisconnected = this.$store.state.walletWasDisconnected

                this.$store.commit('SET_WALLET_WAS_DISCONNECTED', false)
                this.$store.commit('SET_PROVIDER', window.ethereum)
                await this.$store.dispatch('connect', {
                    provider: window.ethereum
                })
            }
            catch(err)
            {
                console.log(err)
            }

        },
        async connectWalletConnect()
        {
            try
            {
                this.showWeb3Modal = true
                // const web3 = this.$store.state.web3
                const walletWasDisconnected = this.$store.state.walletWasDisconnected
                this.$store.commit('SET_WALLET_WAS_DISCONNECTED', false)
                
                let bridge = "https://bridge.walletconnect.org";
                let qrcode = true;
                // let chainId = this.chainId;
                let rpc = 'https://bsc-dataseed.binance.org/'
                const infura = process.env.VUE_APP_INFURA_ID
                const provider = new WalletConnectProvider({
                    bridge,
                    rpc: {
                        1: 'https://mainnet.infura.io/v3/' + infura,
                        // 3: `https://ropsten.infura.io/v3/${this.infuraId}`,
                        // 4: `https://rinkeby.infura.io/v3/${this.infuraId}`,
                        // 5: `https://goerli.infura.io/v3/${this.infuraId}`,
                        42: 'https://kovan.infura.io/v3/' + infura,
                        // 137: 'https://rpc-mainnet.maticvigil.com/',
                        // // what we do need
                        // 56: 'https://bsc-dataseed.binance.org/',
                        // 97: 'https://data-seed-prebsc-2-s2.binance.org:8545/'
                    },
                    qrcode
                })
                const wasDisconnected = this.$store.state.wasDisconnected
                
                await provider.disconnect()
                
                let enable = await provider.enable()
                this.$store.commit('SET_PROVIDER', provider)
                await this.$store.dispatch('connect', {
                    provider: provider
                })
                // const provider = new WalletConnectProvider({
                //     infuraId: "821ea08c5ab24ab5b4876fb49bbf5102", // Required
                // })
                // await provider.enable()
            }
            catch(err)
            {
                console.log(err)
            }


        },
        async connectTrustWallet()
        {

        }
    }
}
</script>

<style scoped>
        .window {
            position: fixed;
            width: 100vw;
            height: 100vh;
            display: flex;
            top:0;
            align-items: flex-end;
            background: rgba(1, 37, 53, 0.8);
        }
        
        .window__content {
            position: relative;
            left: 0;
            right: 0;
            bottom: 86px;
            width: 90%;
            max-width: 400px;
            height: 286px;
            margin: 0 auto;
            background: #fff;
            border-radius: 9px;
        }
        
        .window__title {
            margin-bottom: 20px;
            padding-top: 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #012535;
        }
        
        .window__choose {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 50%;
            align-items: center;
            justify-content: space-evenly;
        }
        
        @media (min-width: 768px) {
            .window {
                align-items: center;
                
            }
        }
</style>